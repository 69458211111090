import { AsyncComponentLoader, defineAsyncComponent, markRaw } from 'vue';
import { CountryISO } from '../../../types/countries';

const getIcon = (regionCode: CountryISO): AsyncComponentLoader => {
  const fileName = regionCode.toLowerCase();
  return markRaw(
    defineAsyncComponent(
      () => import(`../../../assets/svg/flags/${fileName}.svg`)
    )
  );
};

export default getIcon;
