import { SWEDEN } from '@/types/countries';
import { defineStore } from 'pinia';
import { useCountryStore } from './country-store';

export const useTrackingStore = defineStore('trackingStore', {
  getters: {
    getMarketDeeplinkUrl(): string {
      const countryStore = useCountryStore();
      if (countryStore.country?.id === SWEDEN.id) {
        return 'https://q4j2.adj.st?adj_t=9c9czdj&adj_deep_link=firstvet%3A%2F%2F';
      }

      return 'https://2ser.adj.st?adj_t=1h1fo0m&adj_deep_link=firstvet%3A%2F%2F';
    },
  },
});
