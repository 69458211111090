import { AsyncComponentLoader } from 'vue';
import getIcon from '../components/UI/flags';
import {
  DE_COUNTRY_ID,
  DK_COUNTRY_ID,
  FI_COUNTRY_ID,
  NO_COUNTRY_ID,
  SV_COUNTRY_ID,
  UK_COUNTRY_ID,
  US_COUNTRY_ID,
} from '../constants';

export type CountryISO = 'us' | 'se' | 'uk' | 'de' | 'no' | 'dk' | 'fi';

export type RegionCode = 'US' | 'SE' | 'GB' | 'DE' | 'NO' | 'DK' | 'FI';

export type CountryId =
  | typeof US_COUNTRY_ID
  | typeof UK_COUNTRY_ID
  | typeof SV_COUNTRY_ID
  | typeof DE_COUNTRY_ID
  | typeof NO_COUNTRY_ID
  | typeof DK_COUNTRY_ID
  | typeof FI_COUNTRY_ID;

export type CountryName =
  | 'United States'
  | 'Sweden'
  | 'United Kingdom'
  | 'Germany'
  | 'Norway'
  | 'Denmark'
  | 'Finland';

export default interface Country {
  iso: CountryISO;
  id: CountryId;
  name: CountryName;
  regionCode: RegionCode;
  flagComponent: AsyncComponentLoader;
  platform?: 'sv' | 'intl' | 'us';
}

export const USA: Country = {
  iso: 'us',
  id: US_COUNTRY_ID,
  name: 'United States',
  regionCode: 'US',
  platform: 'us',
  flagComponent: getIcon('us'),
};

export const UNITED_KINGDOM: Country = {
  iso: 'uk',
  id: UK_COUNTRY_ID,
  name: 'United Kingdom',
  regionCode: 'GB',
  flagComponent: getIcon('uk'),
};

export const SWEDEN: Country = {
  iso: 'se',
  id: SV_COUNTRY_ID,
  name: 'Sweden',
  regionCode: 'SE',
  flagComponent: getIcon('se'),
  platform: 'sv',
};

export const GERMANY: Country = {
  iso: 'de',
  id: DE_COUNTRY_ID,
  name: 'Germany',
  regionCode: 'DE',
  flagComponent: getIcon('de'),
};

export const NORWAY: Country = {
  iso: 'no',
  id: NO_COUNTRY_ID,
  name: 'Norway',
  regionCode: 'NO',
  flagComponent: getIcon('no'),
};

export const DENMARK: Country = {
  iso: 'dk',
  id: DK_COUNTRY_ID,
  name: 'Denmark',
  regionCode: 'DK',
  flagComponent: getIcon('dk'),
};

export const FINLAND: Country = {
  iso: 'fi',
  id: FI_COUNTRY_ID,
  name: 'Finland',
  regionCode: 'FI',
  flagComponent: getIcon('fi'),
};
