export const __PROD__: boolean = import.meta.env.PROD;

export const __STAGING__: boolean = import.meta.env.DEV;

export const __DEV__: boolean = import.meta.env.DEV;

export const API_URL_INTL: string | boolean | undefined = import.meta.env
  .VITE_APP_API_URL_INTL;

export const API_URL_SV: string | boolean | undefined = import.meta.env
  .VITE_APP_API_URL_SV;

export const API_URL_US: string | boolean | undefined = import.meta.env
  .VITE_APP_API_URL_US;

export const SENTRY_URL: string | boolean | undefined = import.meta.env
  .VITE_APP_SENTRY_URL;

export const BASE_URL: string | boolean | undefined = import.meta.env
  .VITE_APP_BASE_URL;

export const US_STRIPE_PK: string | boolean | undefined = import.meta.env
  .VITE_APP_US_STRIPE_PK;

export const UK_STRIPE_PK: string | boolean | undefined = import.meta.env
  .VITE_APP_UK_STRIPE_PK;

export const DE_STRIPE_PK: string | boolean | undefined = import.meta.env
  .VITE_APP_DE_STRIPE_PK;

export const DK_STRIPE_PK: string | boolean | undefined = import.meta.env
  .VITE_APP_DK_STRIPE_PK;

export const GA_TRACKING_ID: string | boolean | undefined = import.meta.env
  .VITE_APP_GA_TRACKING_ID;

export const AUTH_CLIENT_URL: string | boolean | undefined = import.meta.env
  .VITE_APP_AUTH_CLIENT_URL;

export const AUTH_COOKIE_NAME: string | undefined = import.meta.env.VITE_APP_AUTH_COOKIE_NAME;

export const GTM_ID = 'GTM-5FTVTXV';

export const GTM_APP_NAME = 'get-started.firstvet.com';

export const US_COUNTRY_ID = 840;
export const SV_COUNTRY_ID = 752;
export const UK_COUNTRY_ID = 826;
export const DE_COUNTRY_ID = 276;
export const NO_COUNTRY_ID = 578;
export const DK_COUNTRY_ID = 208;
export const FI_COUNTRY_ID = 246;

export const USER_STATUS_TEMPORARY = 'Temporary';

