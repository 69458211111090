import { createRouter, createWebHistory } from 'vue-router';
import { guard } from './guards';
import { defaultMarket, usMarket } from './market-routes';
import { remoteConfirmRoutes } from '@/features/remote-confirm/router';
const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...remoteConfirmRoutes,

    {
      path: '/:country?',
      name: 'signup',
      component: () => import('@/views/Signup.vue'),
      meta: {
        requireAuth: false,
        showCountrySelect: true,
      },
      children: [
        {
          path: 'redirect',
          name: 'auth-redirect',
          component: () => import('@/views/AuthenticatedRedirect.vue'),
          meta: {
            showBackButton: false,
            showCountrySelect: false,
          },
        },
        {
          path: 'animal-type',
          name: 'animal-type',
          component: () => import('@/views/AnimalType.vue'),
          meta: {
            showBackButton: false,
            showCountrySelect: true,
          },
        },
        {
          path: 'animal-info',
          name: 'animal-info',
          component: () => import('@/views/AnimalName.vue'),
          meta: {
            showBackButton: true,
            showCountrySelect: false,
          },
        },
        {
          path: 'user-info',
          name: 'user-info',
          component: () => import('@/views/UserInformation.vue'),
          meta: {
            showBackButton: true,
            showCountrySelect: false,
          },
        },
        // {
        //   path: 'mobile-phone',
        //   name: 'mobile-phone',
        //   component: () => import('@/views/MobilePhoneVerification.vue'),
        //   meta: {
        //     showBackButton: true,
        //     showCountrySelect: false,
        //   },
        // },
        // {
        //   path: 'mobile-phone-confirm',
        //   name: 'mobile-phone-confirm',
        //   component: () => import('@/views/ConfirmAccount.vue'),
        //   meta: {
        //     showBackButton: false,
        //     showCountrySelect: false,
        //   },
        // },
        {
          path: 'welcome',
          name: 'post-signup',
          component: () => import('@/views/Welcome.vue'),
          meta: {
            showBackButton: false,
            showCountrySelect: false,
          },
        },
        {
          path: 'subscription-select',
          name: 'subscription-select',
          component: () => import('@/views/SubscriptionSelect.vue'),
          meta: {
            showBackButton: false,
          },
        },
        {
          path: 'subscription-checkout',
          name: 'subscription-checkout',
          component: () => import('@/views/SubscriptionCheckout.vue'),
          meta: {
            showBackButton: false,
            requireAuth: true,
            showCountrySelect: false,
          },
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { el: '#main', top: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, from, next) => {
  return guard(to, from, next);
});

export const goToNext = (fromRoute?: string, country?: string) => {
  if (!fromRoute) {
    fromRoute = router.currentRoute.value.name as string;
  }
  if (!country) {
    country = router.currentRoute.value.params.country as string;
  }

  const nextRoute = findNextRoute(fromRoute, country);
  if (nextRoute) {
    router.push({
      name: nextRoute,
      params: { country },
    });
  } else {
    console.error('No next route from ', fromRoute, country);
  }
};

export const goToFirst = (countryIso?: string | null) => {
  if (!countryIso) {
    countryIso = (router.currentRoute.value.params.country as string) || 'us';
  }
  console.log('Going to first route', { countryIso });
  const flow = getMarketFlow(countryIso);
  router.push({
    name: flow[0],
    params: { country: countryIso },
  });
};

export const findNextRoute = (
  fromRoute: string,
  market: string
): string | null => {
  const flow = getMarketFlow(market);
  let currentIndex = flow.findIndex(name => name === fromRoute);
  return flow[++currentIndex] ?? null;
};

const getMarketFlow = (market: string) => {
  switch (market) {
    case 'us':
      return usMarket;
    default:
      return defaultMarket;
  }
};

export default router;
