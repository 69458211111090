import { defineStore } from 'pinia';
import AnimalType, { animalTypes } from '@/types/animal-types';
import { createAnimal } from '@/api/animal';

interface AnimalState {
  animalTypes: AnimalType[];
  animalName: string;
  animalTypeId: string | number | boolean;
  animal: {} | null;
}

export const useAnimalStore = defineStore('animal', {
  persist: {
    storage: window.sessionStorage,
    paths: ['animalName', 'animalTypeId'],
  },
  state: (): AnimalState => {
    return {
      animalTypes: animalTypes,
      animalName: '',
      animalTypeId: false,
      animal: null,
    };
  },
  getters: {
    hasFilledOutAnimalName(): boolean {
      return this.animalName.length > 1;
    },
    hasSelectedAnimalType(): boolean {
      return !!this.animalTypeId;
    },
    inputIsComplete(): boolean {
      return this.hasFilledOutAnimalName && this.hasSelectedAnimalType;
    },
  },
  actions: {
    async addAnimal() {
      if (!this.animalName && !this.animalTypeId) {
        throw new Error(`Couldn't add the pet because of missing information`);
      }

      const animal = await createAnimal(
        this.animalName,
        this.animalTypeId as number
      );
      this.animal = animal;
      return animal;
    },
  },
});
