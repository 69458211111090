import { getTimeZoneName } from '@/utils/timezone-helpers.js';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getDefaultApi, getToken } from '../utils/http-utils/index.js';
import i18n from '@/i18n/i18n.js';
import axiosRetry from 'axios-retry';
import { AUTH_USER_URL } from '@/api/user.js';

const AUTH_TOKEN = getToken();
const browserLocale = i18n.global.locale.value ?? navigator.language;

if (AUTH_TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}
axios.defaults.headers.common['Accept-Language'] = browserLocale ?? 'en';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded';
axios.defaults.headers.common['x-date'] = Date();
axios.defaults.headers.common['x-timezone'] = getTimeZoneName();
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

const config: AxiosRequestConfig = {
  timeout: 60 * 1000, // Timeout
  withCredentials: false,
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  requestConfig => {
    return {
      baseURL: getDefaultApi(),
      ...requestConfig,
    };
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const errorResponse = error.response?.data;
    if (errorResponse) {
      const statusCode = errorResponse.status_code;

      const isValidationError = statusCode === 422;
      if (isValidationError) {
        const isArrayBagOfErrors =
          errorResponse.errors &&
          typeof errorResponse.errors === 'object' &&
          !Array.isArray(errorResponse.errors) &&
          errorResponse.errors !== null;

        if (isArrayBagOfErrors) {
          throw Error(JSON.stringify(Object.values(errorResponse.errors)));
        }
      }
    }
    throw error;
  }
);

// Retry strategy
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential backoff delay of 1000ms
  retryCondition(error: AxiosError) {
    // If the fetch user request error is due to 401, we should retry
    if (
      error.config.url?.includes(AUTH_USER_URL) &&
      error.response?.status === 401
    ) {
      return true;
    }
    // In all else cases, don't retry
    return false;
  },
});

export default axiosInstance;
