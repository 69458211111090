import { myAnimals } from '@/api/animal';
import { updateUserInfo } from '@/api/signup';
import { authenticate } from '@/api/user';
import { defineStore } from 'pinia';
import { useCountryStore } from '.';
import {
  US_STRIPE_PK,
  UK_STRIPE_PK,
  DE_STRIPE_PK,
  DK_STRIPE_PK,
  DE_COUNTRY_ID,
  DK_COUNTRY_ID,
  UK_COUNTRY_ID,
  US_COUNTRY_ID,
} from '@/constants';
import { User } from '@/types/user';

export const useUserStore = defineStore('user', {
  persist: {
    storage: window.sessionStorage,
  },
  state: (): UserState => {
    return {
      user: null,
      firstName: '',
      lastName: '',
      email: '',
      status: '',
      animals: [],
      authenticated: false,
      token: '',
      preExisting: false,
      userJourneyIsCompleted: false,
    };
  },
  getters: {
    firstNameIsValid(): boolean {
      return this.firstName.length >= 2;
    },

    lastNameIsValid(): boolean {
      return this.lastName.length >= 2;
    },

    inputIsValid(): boolean {
      return this.firstNameIsValid && this.lastNameIsValid;
    },

    emailIsValid(): boolean {
      return this.email.length > 0;
    },

    stripePublicKey(state): string | undefined {
      if (!state.user) {
        return undefined;
      }
      const userData = state?.user as any;
      switch (userData.country_id) {
        case US_COUNTRY_ID:
          return US_STRIPE_PK as string;
        case UK_COUNTRY_ID:
          return UK_STRIPE_PK as string;
        case DE_COUNTRY_ID:
          return DE_STRIPE_PK as string;
        case DK_COUNTRY_ID:
          return DK_STRIPE_PK as string;
      }
    },
  },
  actions: {
    async updateUser() {
      const countryStore = useCountryStore();
      const countryId = countryStore.country?.id;
      if (!countryId) {
        throw Error('No country ID');
      }
      const { firstName, lastName } = this;

      const user = await updateUserInfo(firstName, lastName, countryId);
      this.user = user;

      return user;
    },

    async fetchUser() {
      const user = await authenticate();
      if (user && user.id) {
        this.user = user;
        this.email = user.email;
        this.status = user.status;
        this.authenticated = true;
        this.preExisting = true;
        return user;
      }
    },
    async fetchAnimals() {
      const animals = await myAnimals();
      if (animals) {
        this.animals = animals;
      }
      return animals;
    },

    logout() {
      this.$reset();
    },
  },
});

interface UserState {
  user: User | null;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  animals: any[];
  authenticated: boolean;
  token: string;
  preExisting: boolean;
  userJourneyIsCompleted: boolean;
}
