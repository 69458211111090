import { useUserStore } from '@/stores';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { setToken } from '@/api/signup';
import Cookies from 'js-cookie';
import {AUTH_COOKIE_NAME, USER_STATUS_TEMPORARY} from "@/constants";
import {authRedirect} from "@/utils/auth-redirect";

export const guard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const userStore = useUserStore();

  if(to.meta.requireAuth && to.meta.redirectToAuth){
    const authCookie = Cookies.get(AUTH_COOKIE_NAME || '');
    if(authCookie && (!userStore.authenticated || userStore.status === USER_STATUS_TEMPORARY)){
      setToken(authCookie);
      useUserStore().fetchUser();
    } else if(!userStore.authenticated){
      authRedirect({
        country: to.params.country as string,
        redirectBackTo: window.location.href
      });
    }
  }
  if (to.meta.requireAuth && !userStore.authenticated) {
    await useUserStore().fetchUser();
  }

  next();
};
