/* eslint-disable @typescript-eslint/camelcase */
const seTranslation = {
  please_wait_while_we_configure_your_account: 'Häng kvar en stund..',
  get_started_with_firstvet: 'Kom igång med FirstVet',
  get_the_app: 'Ladda ner appen',
  visit_the_shop: 'Till butiken',
  continue: 'Fortsätt',

  please_read_our: 'Vänligen läs vår',
  privacy_policy: 'integritetspolicy',
  terms_of_service: 'användarvillkor',
  cookies: 'Cookies',
  and: 'och',
  if_you_are_ok_with_our_terms:
    'Genom att fortsätta bekräftar jag att jag har läst och godkänner villkoren.',
  accept_and_continue: 'Godkänn & fortsätt',
  cancel: 'Avbryt',
  by_continuing_you_agree: 'Genom att fortsätta godkänner du',
  and_confirm_you_have_read: 'och bekräftar att du har läst och tagit del av',
  including_use_of: 'inklusive användningen av',
  tos_text_de_ending: 'läst och förstått',

  are_you_sure: 'Vill du avbryta?',
  not_accepting_our_terms_info:
    'Om du avbryter kan inte FirstVet tillhandahålla vår veterinärtjänst till dig.',
  reconsider_your_decision:
    'Observera att du kan ta bort ditt konto när som helst om du väljer att skapa ett konto.',
  sign_up_to_firstvet: 'Skapa konto',
  go_to_firstvet_website: 'Läs mer på FirstVet.se',

  what_type_of_pet_do_you_have: 'Vilket slags djur har du?',
  add_more_pets_later: 'Du kan lägga till fler djur senare.',
  select_animal_type: 'Välj djurtyp',

  what_is_your_pets_name: 'Vad heter ditt husdjur?',
  add_more_info_about_your_pet_later:
    'Du kan lägga till mer information om ditt husdjur senare.',

  and_a_little_about_you: '...och så lite om dig',
  so_we_can_get_in_touch: 'Så att du kommer åt tjänsten.',
  for_journal_and_prescriptions: 'T.ex. för journalanteckningar & recept',

  phone_number_and_pin_info:
    'Du loggar in med ditt telefonnummer och en PIN-kod som vi skickar till dig via sms. Inget behov av lösenord.',
  send_pin_code: 'Skicka PIN-kod',

  enter_pin_to_confirm_phone_number:
    'Ange den 4-siffriga PIN-koden som vi skickade till dig via sms för att bekräfta ditt telefonnummer.',
  verification_attempts_left:
    'Du kan försöka en gång till. | Du kan försöka {count} gånger till',
  resend_pin: 'Skicka ny PIN-kod',

  success: 'Klart!',
  welcome_to_firstvet:
    'Välkommen till FirstVet. Ladda ner vår app för att utforska tjänsten och boka ditt första veterinärbesök.',
  app_store_rating: 'Betyg i App Store: 4.9',

  choose_a_plan: 'Välj en prenumeration',
  subscription_info:
    'Fria veterinärbesök via video ingår för alla dina husdjur i din prenumeration.',
  purchase: 'Köp prenumeration',
  payment_details: 'Betalningsuppgifter',
  pay: 'Betala',
  yearly_subscription: 'Årlig prenumeration',
  six_months_subscription: '6 månaders prenumeration',
  subscription_desc:
    'Obegränsad tillgång till veterinärbesök via video i {months} månader.',

  forms: {
    labels: {
      first_name: 'Förnamn',
      last_name: 'Efternamn',
      your_email: 'Din e-postadress',
      card_number: 'Kortnummer',
      expiration: 'Giltig till',
      cvc: 'CVC',
      your_phone_number: 'Ditt telefonnummer',
    },
    aria: {
      email: 'Vänligen ange din e-postadress',
      first_name: 'Lägg till ditt förnamn',
      last_name: 'Lägg till ditt efternamn',
      pet_name: 'Lägg till ditt husdjurs namn',
      mobile_phone: 'Mobiltelefonnummer',
      country: 'Välj land',
      download_firstvet: 'Ladda ner FirstVet',
      go_to_fv_shop: 'Gå till butiken',
      fv_shop: 'Butiken',
      go_back: 'Gå tillbaka',
      view_tos: 'Visa användarvillkor',
      view_privacy: 'Visa integritetspolicy',
    },
    placeholders: {
      pet_name: 'Husdjurets namn',
    },
    validation: {
      enter_a_valid_email: 'Ange en giltig e-postadress',
    },
  },

  stripe: {
    confirm_subscription_info:
      'Genom att bekräfta din prenumeration tillåter du att FirstVet debiterar dig för denna betalning och framtida betalningar i enlighet med deras villkor.',
    powered_by_stripe: 'Powered by Stripe',
    terms: 'Användarvillkor',
    privacy: 'Integritetspolicy',
  },

  sms_confirm: {
    confirm_booking: 'Bekräfta din bokning',
    vet_consultation: 'Veterinärbesök',
    confirm_button: 'Bekräfta bokningen',
    info: 'Genom att fortsätta bekräftar jag att jag har läst och godkänner { terms } och { privacy }',
    for: 'för',
  },
  success_page: {
    title: 'Klart!',
    open_app: 'Till FirstVet-appen',
    text: 'Ditt besök sker genom ett videosamtal i FirstVet-appen. Ladda ner appen och logga in i god tid innan mötet startar.',
  },

  with: 'med',
  for: 'för',
  today: 'idag',
  tomorrow: 'imorgon',
  old: 'gammal',
  vet_consultation: 'Veterinärbesök',

  remote_confirm: {
    booking_link_has_expired: 'Den här länken är inte längre giltig',
    booking_link_has_expired_long_description: `Tiden för bokningen som vi skapade åt dig har löpt ut. Gå vidare för att välja en ny tid som passar dig.`,
    confirm_your_booking: 'Bekräfta din bokning',
    confirm_the_booking: 'Bekräfta bokningen',

    link_is_no_longer_valid: 'Den här länken är inte giltig',
    link_reuse_info: 'Vänligen använd den bokningslänk vi skickade till dig.',
    download_app_and_book_instructions:
      'Du kan även ladda ner FirstVet-appen direkt - det är enkelt att lägga till ditt djur och boka en tid som passar dig.',
      login_or_signup: 'Fortsätt för att bekräfta bokningen',
      login_or_signup_text : '',
    tos_accept: {
      text: 'Jag bekräftar att jag vill skapa ett konto hos FirstVet och godkänner {terms} och bekräftar att jag har läst och förstått {privacy_policy}.',
      terms: {
        text: 'användarvillkoren',
        url: 'https://firstvet.com/sv/anvandarvillkor',
      },
      privacy_policy: {
        url: 'https://firstvet.com/sv/integritetspolicy',
        text: 'integritetspolicyn',
      },
    },
    select_a_new_time: 'Välj en ny tid',
    select_a_time: 'Välj en tid',
    no_more_times_today: 'Det finns inga fler tider den här dagen.',
  },
};

export default seTranslation;
