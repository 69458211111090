import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from '@/App.vue';
import router from '@/router';
import VueGtag from 'vue-gtag';

import i18n from './i18n/i18n';

import { useSentry } from './plugins/sentry';

import { __PROD__, __STAGING__ } from './constants';
import gtagOptions from './utils/tracking/gtag-options';

import '@/assets/index.postcss';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

if (__PROD__ || __STAGING__) {
  useSentry(app, router);
}

app.use(router).use(pinia).use(i18n).use(VueGtag, gtagOptions).mount('#app');
