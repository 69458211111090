import * as Sentry from '@sentry/vue';
import { Router } from 'vue-router';
import { BASE_URL, __PROD__, SENTRY_URL } from '@/constants';

export const useSentry = (app: any, router: Router): void => {
  // Set tracesSampleRate to 25% in production and 100% in staging
  // Documentation here: https://docs.sentry.io/platforms/javascript/configuration/sampling/
  const tracesSampleRate = __PROD__ ? 0.25 : 1;

  Sentry.init({
    app,
    dsn: SENTRY_URL as string,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate,

    tracePropagationTargets: [
      'localhost',
      /^https:\/\/us.app.firstvet\.com\/api/,
      /^https:\/\/app.firstvet\.com\/api/,
      /^https:\/\/sv.app.firstvet\.com\/api/,
    ],

    replaysSessionSampleRate: __PROD__ ? 0.1 : 0,
    replaysOnErrorSampleRate: __PROD__ ? 1.0 : 0,
  });
};
