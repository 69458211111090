import axios from '@/plugins/axios';
import { Animal } from '@/types/animal';

export const createAnimal = async (
  name: string,
  animalTypeId: number | null
): Promise<Animal> => {
  const { data } = await axios.post(`/api/my/animals`, { name, animalTypeId });
  return data;
};

export const getAnimal = async (id: string | number): Promise<Animal> => {
  const { data } = await axios.get(`/api/v2/animals/${id}`);
  return data.data;
};

export const myAnimals = async (): Promise<Animal[]> => {
  const { data } = await axios.get('/api/v2/animals');
  return data.data;
};
