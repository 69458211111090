/* eslint-disable @typescript-eslint/camelcase */
const dkTranslation: object = {
  please_wait_while_we_configure_your_account:
    'Please wait while we figure out things',
  get_started_with_firstvet: 'Get started with FirstVet',
  get_the_app: 'Get the app',
  visit_the_shop: 'Visit the shop',
  continue: 'Continue',

  please_read_our: 'Please read our',
  privacy_policy: 'Privacy Policy',
  terms_of_service: 'Terms of Service',
  cookies: 'Cookies',
  and: 'and',
  if_you_are_ok_with_our_terms:
    'If you are ok with them, accept both to continue.',
  accept_and_continue: 'Accept & Continue',
  cancel: 'Cancel',
  by_continuing_you_agree: 'By continuing, you agree to our',
  and_confirm_you_have_read:
    'and confirm that you have read and understood our',
  including_use_of: 'including use of',
  tos_text_de_ending: '',

  are_you_sure: 'Are you sure?',
  not_accepting_our_terms_info:
    'You are obviously free to not accept our Terms of Service. But by doing so we are not legally able to provide our service to you.',
  reconsider_your_decision:
    'We hope you will reconsider your decision. Note that you can delete your account at any time after signing up.',
  sign_up_to_firstvet: 'Sign up to FirstVet',
  go_to_firstvet_website: 'Go to FirstVet.com',

  what_type_of_pet_do_you_have: 'What type of pet do you have?',
  add_more_pets_later: 'You can add more pets later.',
  select_animal_type: 'Select animal type',

  what_is_your_pets_name: "What's your pet's name?",
  add_more_info_about_your_pet_later:
    'More information about your pet can be added later.',

  and_a_little_about_you: '...and a little about you',
  so_we_can_get_in_touch: 'So we can get in touch.',
  for_journal_and_prescriptions: 'E.g. for journal & prescriptions',

  phone_number_and_pin_info:
    'Your login will be your phone number and PIN code, sent via text. No need for password.',
  send_pin_code: 'Send PIN code',

  enter_pin_to_confirm_phone_number:
    'Enter the 4 digit PIN sent via text to confirm your phone your phone number.',
  verification_attempts_left:
    'You can try one more time. | Your can try {count} more times',
  resend_pin: 'Resend the PIN Code',

  success: 'Success!',
  welcome_to_firstvet:
    'Welcome to FirstVet. Download our app to explore the service and make your first vet appointment.',
  app_store_rating: 'App Store rating: 4.9',

  choose_a_plan: 'Choose a plan',
  subscription_info:
    'All pets included with one subscription and no appointments is necessary to get help.',
  purchase: 'Purchase',
  payment_details: 'Payment details',
  pay: 'Pay',
  yearly_subscription: 'Yearly subscription',
  six_months_subscription: '6 months subscriptions',
  subscription_desc:
    'Unlimited 24/7 access to live licensed vet chat or video consults for {months} months.',

  forms: {
    labels: {
      first_name: 'First name',
      last_name: 'Last name',
      your_email: 'Your Email',
      card_number: 'Card number',
      expiration: 'Expiration',
      cvc: 'CVC',
      your_phone_number: 'Your phone number',
    },
    aria: {
      email: 'Please enter your email',
      first_name: 'Enter your first name',
      last_name: 'Enter your last name',
      pet_name: "Enter your pet's name",
      mobile_phone: 'Mobile phone',
      country: 'Select country',
      download_firstvet: 'Download FirstVet',
      go_to_fv_shop: 'Go to FirstVet shop',
      fv_shop: 'FirstVet Shop',
      go_back: 'Go back',
      view_tos: 'View terms of service',
      view_privacy: 'View privacy policy',
    },
    placeholders: {
      pet_name: "Your pet's name",
    },
    validation: {
      enter_a_valid_email: 'Please enter a valid email',
    },
  },

  stripe: {
    confirm_subscription_info:
      'By confirming your subscription, you allow FirstVet to charge your card for this payment and future payments in accordance with their terms.',
    powered_by_stripe: 'Powered by Stripe',
    terms: 'Terms',
    privacy: 'Privacy',
  },
  sms_confirm: {
    confirm_booking: 'Confirm your booking',
    vet_consultation: 'Vet consultation',
    confirm_button: 'Confirm the booking',
    info: 'By continuing you confirm that you have read and accept our { terms } and { privacy }',
    for: 'For',
  },
  success_page: {
    title: 'Booking confirmed',
    open_app: 'Open FirstVet app',
    text: 'The consultation will happen in the FirstVet app. Make sure you have setup the app before the appointment.',
  },

  with: 'with',
  for: 'for',
  today: 'today',
  tomorrow: 'tomorrow',
  old: 'old',
  vet_consultation: 'Vet consultation',

  remote_confirm: {
    booking_link_has_expired: '',
    booking_link_has_expired_long_description: '',
    confirm_your_booking: '',
    confirm_the_booking: '',

    link_is_no_longer_valid: '',
    link_reuse_info: '',
    download_app_and_book_instructions: '',
    login_or_signup: 'Gå videre for at bekræfte bookingen',
    login_or_signup_text : '',
    tos_accept: {
      text: `I confirm that I want to create an account with FirstVet and accept the
        {terms} and confirm that I have read and understood the
        {privacy_policy}.`,
      terms: {
        text: 'Terms & Conditions',
        url: '#',
      },
      privacy_policy: {
        text: 'Privacy Policy',
        url: '#',
      },
    },
    select_a_new_time: 'Select a new time',
    select_a_time: 'Select a time',
    no_more_times_today: 'There are no more times for this day.',
  },
};

export default dkTranslation;
