import Country from '@/types/countries';

export function setPlatform(country: Country) {
  const platform = getAPIPlatform(country);
  sessionStorage.setItem('platform', platform);
}

export function getAPIPlatform(country: Country) {
  if (country.platform) {
    return country.platform;
  }
  return 'intl';
}
