import axios from '@/plugins/axios';
import Country from '@/types/countries';
import { Subscription, SubscriptionPlan } from '@/types/subscription-types';

export const getAvailableSubscriptionPlans = async (
  country: Country
): Promise<Array<SubscriptionPlan>> => {
  const { data } = await axios.get(
    `/api/subscriptions?countryId=${country.id}`
  );

  return data.subscriptionPlans.map((plan: any) => {
    return {
      id: plan.id,
      name: plan.title,
      recurringIntervalCount: plan.recurring_interval_count,
      price: plan.price,
      priceId: plan.price_id,
      productId: plan.product_id,
    } as SubscriptionPlan;
  });
};

export const createSetupIntent = async () => {
  const { data } = await axios.post('/api/stripe/subscription/intent');
  return data;
};

export const getMySubscriptions = async () => {
  const { data } = await axios.get('/api/my/subscriptions');

  if (!Array.isArray(data)) {
    throw new Error('No subscriptions found');
  }

  return data.map((sub: any) => {
    return {
      id: sub.id,
      name: sub.name,
      status: sub.stripe_status,
      planId: sub.stripe_plan,
      quantity: sub.quantity,
      active: sub.active,
    } as Subscription;
  });
};

export const createSubscription = (
  priceId: String,
  paymentMethodId: String
): Promise<Subscription> => {
  return axios
    .post('/api/stripe/create/subscription', {
      priceId,
      paymentMethodId,
    })
    .then((response: any) => {
      const { userSubscription } = response.data;
      return {
        id: userSubscription.id,
        name: userSubscription.name,
        status: userSubscription.stripe_status,
        planId: userSubscription.stripe_plan,
        quantity: userSubscription.quantity,
        active: userSubscription.active,
        stripeId: userSubscription.stripe_id,
      } as Subscription;
    });
};
