import { defineStore } from 'pinia';

import Country, {
  DENMARK,
  FINLAND,
  GERMANY,
  NORWAY,
  SWEDEN,
  UNITED_KINGDOM,
  USA,
} from '../types/countries';
import { setPlatform } from '@/utils/countries';

interface CountryState {
  countries: Country[];
  country: Country | null;
}

export const useCountryStore = defineStore('country', {
  persist: {
    storage: window.sessionStorage,
    paths: ['country.iso', 'country.id'],
  },
  state: (): CountryState => {
    return {
      countries: [
        SWEDEN,
        USA,
        UNITED_KINGDOM,
        GERMANY,
        NORWAY,
        DENMARK,
        FINLAND,
      ],
      country: null,
    };
  },
  getters: {},
  actions: {},
});

export function setCountry(country: Country) {
  const countryStore = useCountryStore();
  countryStore.country = country;
  sessionStorage.setItem('country_iso', country.iso);
  setPlatform(country);
}
