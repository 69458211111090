import {
  getAvailableSubscriptionPlans,
  getMySubscriptions,
} from '@/api/subscription';
import Country from '@/types/countries';
import { Subscription, SubscriptionPlan } from '@/types/subscription-types';
import { defineStore } from 'pinia';

export const useSubscriptionStore = defineStore('subscription', {
  persist: {
    storage: window.sessionStorage,
  },
  state: (): SubscriptionState => {
    return {
      selectedPlan: null,
      subscriptions: [],
      plans: null,
    };
  },
  getters: {
    hasActiveSubscription(): Boolean {
      return this.subscriptions.some((subscription: Subscription) => {
        return subscription.active;
      });
    },
  },
  actions: {
    async fetchSubscriptions() {
      this.subscriptions = await getMySubscriptions();
    },
    async fetchAvailablePlans(country: Country) {
      this.plans = await getAvailableSubscriptionPlans(country);
    },
  },
});

interface SubscriptionState {
  selectedPlan: SubscriptionPlan | null;
  subscriptions: Array<Subscription>;
  plans: Array<SubscriptionPlan> | null;
}
