import axios from '@/plugins/axios';
import { User } from '@/types/user';

export const AUTH_USER_URL = '/api/auth/user';
export const USER_URL = '/api/v2/user';

export const authenticate = async () => {
  try {
    const { data } = await axios.get(AUTH_USER_URL);
    // set analytics identify?
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUser = async (tos: Date): Promise<User> => {
  try {
    const { data } = await axios.put(USER_URL, {
      tos_accepted: tos,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postRegister = async (): Promise<void> => {
  try {
    await axios.post('api/v2/auth/post-register');
    return;
  } catch (error) {
    return Promise.reject(error);
  }
};
