export default interface AnimalType {
  id: number;
  key: string;
  name: string;
}

export const TYPE_DOG: AnimalType = { id: 1, name: 'Dog', key: 'dog' };
export const TYPE_CAT: AnimalType = { id: 2, name: 'Cat', key: 'cat' };
export const TYPE_RABBIT: AnimalType = { id: 3, name: 'Rabbit', key: 'rabbit' };
export const TYPE_BIRD: AnimalType = { id: 4, name: 'Bird', key: 'bird' };
export const TYPE_REPTILE: AnimalType = {
  id: 5,
  name: 'Reptile',
  key: 'reptile',
};
export const TYPE_RODENT: AnimalType = { id: 6, name: 'Rodent', key: 'rodent' };
export const TYPE_MINIPIG: AnimalType = {
  id: 8,
  name: 'Mini-pig',
  key: 'minipig',
};
export const TYPE_INSECT: AnimalType = {
  id: 9,
  name: 'Insect/Arachnid',
  key: 'insect_arachnida',
};
export const TYPE_HORSE: AnimalType = { id: 10, name: 'Horse', key: 'horse' };
export const TYPE_FISH: AnimalType = { id: 11, name: 'Fish', key: 'fish' };

export const animalTypes: AnimalType[] = [
  TYPE_DOG,
  TYPE_CAT,
  TYPE_RODENT,
  TYPE_RABBIT,
  TYPE_HORSE,
  TYPE_BIRD,
  TYPE_REPTILE,
  TYPE_MINIPIG,
  TYPE_INSECT,
  TYPE_FISH,
];
