import Cookies from 'js-cookie';
import { API_URL_INTL, API_URL_SV, API_URL_US } from '../../constants';

const getPlatform = (): string => {
  const platform = sessionStorage.getItem('platform');
  if (!platform) {
    return 'intl';
  }
  return platform;
};

const getDefaultApi = (): string | boolean | undefined => {
  const platformString = getPlatform();
  switch (platformString) {
    case 'sv':
      return API_URL_SV;
    case 'us':
      return API_URL_US;
    case 'intl':
      return API_URL_INTL;
    default:
      return API_URL_INTL;
  }
};

export const getToken = (): string | null => {
  const token = sessionStorage.getItem('token') ?? Cookies.get('firstvetToken');
  return token ?? null;
};

export { getDefaultApi, getPlatform };
