import {AUTH_CLIENT_URL} from "@/constants";

interface AuthRedirectProps {
    country?: string
    redirectBackTo: string;
}

export const authRedirect = ({
     country = 'sv',
     redirectBackTo
}: AuthRedirectProps) => {
    window.location.href = AUTH_CLIENT_URL + '/' + country + '/?source=web.confirm&redirect_url=' + redirectBackTo;
}
