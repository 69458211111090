import axios from '@/plugins/axios';
import { CountryId } from '@/types/countries';
import { User } from '@/types/user';

export const updateUserInfo = async (
  firstName: string | null,
  lastName: string | null,
  countryId: CountryId
) => {
  let payload = {};
  if (!firstName || !lastName) {
    payload = { countryId };
  } else {
    payload = { firstName, lastName, countryId };
  }

  const { data } = await axios.put(`/api/v2/user`, payload);

  return data.data as User;
};

export const setToken = (token: string): void => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  sessionStorage.setItem('token', token);
};

export interface SignupResponse {
  token: string | undefined;
  preExisting: boolean;
  user: User;
}
