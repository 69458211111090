export const defaultMarket = [
  'animal-type',
  'animal-info',
  'user-info',
  'auth-redirect',
  // 'mobile-phone',
  // 'mobile-phone-confirm',
  'post-signup',
];

export const usMarket = [
  'subscription-select',
  'user-info',
  'auth-redirect',
  // 'mobile-phone',
  // 'mobile-phone-confirm',
  'subscription-checkout',
  'post-signup',
];
