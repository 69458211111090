
import { GA_TRACKING_ID } from '@/constants'

const gtagOptions = {
  appName: 'Signup App',
  config: {
    id: GA_TRACKING_ID,
    params: {
      send_page_view: false,
    },
  }
}

export default gtagOptions;